import React, { useState, useEffect } from "react";
import { callApi } from "../../utils/api";
import VideoJS from "./video";
import { apiRequest, cdn } from "../../authConfig";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ProtectedVideo = ({ videoJson, accessToken }) => {
  const location = useLocation();
  const cdnUrl =
    location.hostname === "www.mizuno-ch.cn" ? cdn.url_cn : cdn.url;

  // https://docs.videojs.com/tutorial-react.html
  const playerRef = React.useRef(null);
  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    //playbackRates: [0.5, 1, 1.5, 2],
    responsive: true,
    fluid: true,
    sources: [
      {
        src:
          cdnUrl + `/${videoJson.type}/videos/${videoJson.slug}/playlist.m3u8`,
        type: "application/x-mpegURL",
      },
    ],
    poster: cdnUrl + `/public/thumbnails/${videoJson.slug}.webp`,
    html5: {
      vhs: {
        overrideNative: true,
      },
    },
  };
  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  // Call api
  const [apiData, setApiData] = useState(null);
  const url = new URL(apiRequest.url);

  useEffect(() => {
    if (accessToken) {
      callApi(accessToken, url + `?type=${videoJson.type}`)
        .then((response) => response.json())
        .then((data) => {
          setApiData(data);
        })
        .catch((error) => console.log(error));
    }
  }, [accessToken]);

  if (apiData) {
    return apiData.purchased === true ? (
      <>
        <VideoJS
          options={videoJsOptions}
          onReady={handlePlayerReady}
          sas={apiData.sas}
          videoJson={videoJson}
        />
        {videoJson.series !== "katari" && videoJson.series !== "taidan" ? (
          <div className="flex justify-center">
            <a
              href={
                cdnUrl +
                `/${videoJson.type}/docs/${videoJson.slug}.pdf` +
                apiData.sas
              }
              download
              target="_blank"
              rel="noreferrer"
              className="inline-block px-5 md:px-10 py-2 md:py-3 bg-cyan-800 hover:bg-cyan-700 text-white rounded-md mt-5 text-center font-sans tracking-wider"
            >
              レジュメPDFダウンロード
            </a>
          </div>
        ) : null}
      </>
    ) : (
      <div className="relative">
        {/* <img
          src={cdnUrl + `/public/thumbnails/${videoJson.slug}.png`}
          alt={videoJson.title}
        /> */}
        <GatsbyImage image={getImage(videoJson.image)} alt={videoJson.slug} />
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="text-white text-sm md:text-lg text-center bg-gray-900 bg-opacity-80 p-3 md:p-10">
            この動画はオプション会員様限定です。
            <br />
            オプションのお申し込みは弊社担当者または
            <Link to="/contact" className="text-cyan-500 hover:text-cyan-400">
              お問い合わせ
            </Link>
            よりお願いいたします。
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full aspect-video flex items-center justify-center">
        <div className="animate-spin h-10 w-10 border-4 border-cyan-500 rounded-full border-t-transparent"></div>
      </div>
    );
  }
};

export default ProtectedVideo;
