// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/gatsby-sample/src/utils/MsGraphApiCall.js
export const callApi = async (accessToken, url) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(url, options);
};
