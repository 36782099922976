import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../components/layout";
import {
  useMsal,
  AuthenticatedTemplate,
  useIsAuthenticated,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import ProtectedVideo from "../../components/video/protectedVideo";
import { apiRequest } from "../../authConfig";
import { ChevronRightIcon } from "@heroicons/react/solid";
import FreeVideo from "../../components/video/freeVideo";
import Seo from "../../components/seo";
import { loginRequest } from "../../authConfig";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const VideoJson = (props) => {
  const { videoJson } = props.data;

  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      if (inProgress === InteractionStatus.None) {
        const accessTokenRequest = {
          scopes: apiRequest.scopes,
          account: accounts[0],
        };
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then((response) => setAccessToken(response.accessToken))

          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance
                .acquireTokenPopup(accessTokenRequest)
                .then((response) => setAccessToken(response.accessToken))
                .catch((error) => console.log(error));
            }
            console.log(error);
          });
      }
    }
  }, [instance, accounts, inProgress, isAuthenticated]);

  return (
    <Layout>
      <Seo title={videoJson.title} />
      <main>
        <div className="bg-gray-100 text-gray-600">
          <nav className="container mx-auto p-1 md:p-3 font-sans">
            <ol className="list-reset flex items-center text-sm">
              <li>
                <Link to="/" className="text-blue-600 hover:text-blue-700">
                  ホーム
                </Link>
              </li>
              <li className="mx-1">
                <ChevronRightIcon className="w-4 h-4" />
              </li>
              <li>
                <Link
                  to="/videos"
                  className="text-blue-600 hover:text-blue-700"
                >
                  中国ビジネス解説動画
                </Link>
              </li>
              <li className="mx-1">
                <ChevronRightIcon className="w-4 h-4" />
              </li>
              <li>
                {videoJson.title.length > 11
                  ? videoJson.title.substring(0, 10) + "..."
                  : videoJson.title}
              </li>
            </ol>
          </nav>
        </div>
        <div className="container mx-auto max-w-5xl px-5 md:px-10 py-10 md:py-20">
          {videoJson.type === "free" ? (
            <FreeVideo videoJson={videoJson} withDoc={true} />
          ) : (
            <>
              <AuthenticatedTemplate>
                <ProtectedVideo
                  videoJson={videoJson}
                  accessToken={accessToken}
                />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <div className="relative">
                  <GatsbyImage
                    image={getImage(videoJson.image)}
                    alt={videoJson.slug}
                  />
                  <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                    <div className="text-white text-sm md:text-lg text-center bg-gray-900 bg-opacity-80 p-3 md:p-10">
                      <div className="mb-3">
                        この動画は{videoJson.type === "option" && "オプション"}
                        会員様限定です。
                        <br />
                        会員のお申し込み、コンサルティングのご相談は
                        <Link
                          to="/contact"
                          className="text-cyan-500 hover:text-cyan-400"
                        >
                          お問い合わせ
                        </Link>
                        よりお願いいたします。
                      </div>
                      <div className="mb-3">
                        {videoJson.type === "option" && "オプション"}
                        会員様はログインをお願いいたします。
                      </div>
                      <div className="inline-flex justify-center">
                        <button
                          className="border rounded-sm md:px-4 md:py-2 px-2 py-1 font-medium border-cyan-500 bg-cyan-50 hover:bg-cyan-100 text-gray-900"
                          onClick={() => instance.loginPopup(loginRequest)}
                        >
                          会員ログイン
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </UnauthenticatedTemplate>
            </>
          )}
          <div className="font-sans mt-5">
            <h1 className="md:text-2xl text-lg font-medium mb-3">
              {videoJson.title}
            </h1>
            <div className="text-sm mb-3">
              {videoJson.created}
              {videoJson.category ? " | " + videoJson.category : null}
            </div>
            <div>{videoJson.description}</div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default VideoJson;

export const query = graphql`
  query ($id: String) {
    videoJson(id: { eq: $id }) {
      id
      category
      created
      description
      no
      slug
      series
      title
      type
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;
